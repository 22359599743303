import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://fad527a424593306b2d0cb22d5fc3dea@o4506679064723456.ingest.sentry.io/4506679066558464",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});